import React, { useState } from 'react';

export const Dock = ({ children, magnification = 2, distance = 100 }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="flex justify-center items-end h-16 bg-gray-800 rounded-t-lg">
      {React.Children.map(children, (child, index) => {
        const scale = hoveredIndex === null
          ? 1
          : 1 + (magnification - 1) * (1 - Math.min(Math.abs(hoveredIndex - index) * distance / 100, 1));

        return React.cloneElement(child, {
          style: { transform: `scale(${scale})` },
          onMouseEnter: () => setHoveredIndex(index),
          onMouseLeave: () => setHoveredIndex(null),
        });
      })}
    </div>
  );
};

export const DockIcon = ({ children, className, ...props }) => (
  <div className={`w-12 h-12 mx-1 transition-transform duration-200 ease-out ${className}`} {...props}>
    {children}
  </div>
);