import React, { useEffect, useState } from 'react';
import { Dock, DockIcon } from './components/Dock';
import BlurIn from './components/BlurIn';
import PhotoCarousel from './components/PhotoCarousel';
import IframeModal from './components/IframeModal';
import './App.css';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleNavClick = (e) => {
      const href = e.currentTarget.getAttribute('href');
      if (href.startsWith('#')) {
        e.preventDefault();
        const targetId = href.substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const headerHeight = document.querySelector('.App-header').offsetHeight;
          const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - headerHeight - 20;
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      }
    };

    const navLinks = document.querySelectorAll('.nav-button');
    navLinks.forEach(link => link.addEventListener('click', handleNavClick));

    return () => {
      navLinks.forEach(link => link.removeEventListener('click', handleNavClick));
    };
  }, []);

  const photos = [
    `${process.env.PUBLIC_URL}/img/About1.png`,
    `${process.env.PUBLIC_URL}/img/About2.png`,
    `${process.env.PUBLIC_URL}/img/About3.png`,
  ];

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className="App font-gluten font-light">
      <header className="App-header">
        <h1 className="font-lacquer">Bullet Coin</h1>
        <nav className="center-nav">
          <a href="#about" className="nav-button clear-button">About</a>
          <a href="#merch" className="nav-button clear-button">Merch</a>
          <a href="#tokenomics" className="nav-button clear-button">Tokenomics</a>
          <a href="#donate" className="nav-button clear-button">Donate</a>
        </nav>
        <div className="right-section">
          <div className="header-social-links">
            <a href="https://t.me/BulletCoinBase" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-telegram"></i>
            </a>
            <a href="https://x.com/BulletCoinBase" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-x-twitter"></i>
            </a>
          </div>
          <a 
            href="https://app.uniswap.org/swap?theme=dark&inputCurrency=0x4200000000000000000000000000000000000006&outputCurrency=0xda34ca1649cad55be915027fb3be31e26f0f955f&chain=base" 
            target="_blank" 
            rel="noopener noreferrer"
            className="nav-button buy-button"
          >
            Buy Now
          </a>
        </div>
      </header>
      
      <main className="flex flex-col items-center">
        <section id="hero" className="section-container w-full max-w-6xl">
          <div className="hero-content frame-content">
            <h2 className="hero-text">
              <BlurIn word="BULLET" className="font-lacquer text-hero text-bullet-blue" />
            </h2>
            <img src={`${process.env.PUBLIC_URL}/img/Bulletfather.png`} alt="Bullet Father" className="hero-image" />
          </div>
        </section>

        <section id="about" className="section-container w-full max-w-6xl">
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 p-4">
              <div className="frame-content">
                <h2 className="text-5xl font-bold mb-12 font-lacquer text-bullet-blue">About</h2>
                <div className="text-white">
                  <p className="text-xl mb-6">
                    Welcome to the wild world of Bullet Coin—where the bull runs strong and the memes are legendary! Born on the Base chain, Bullet Coin isn't just a token; it's a community-powered movement with a mission to bring fun, culture, and creativity to the crypto space.
                  </p>
                  <p className="text-xl mb-6">
                    Inspired by a story made up for our kids, Bullet is more than just a coin—he's a fearless bull, charging through the digital landscape, spreading good vibes, and rallying the herd. We're here to celebrate the spirit of the bull market, one meme at a time.
                  </p>
                  <p className="text-xl mb-6">
                    Whether you're here to hodl, trade, or just enjoy the ride, Bullet Coin is your ticket to a world where crypto meets culture. Join the herd, rock the merch, and let's take this bull to the moon!
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-4">
              <PhotoCarousel photos={photos} />
              <div className="mt-6 frame-content">
                <p className="font-bold text-xl mb-4 text-white">Contract Address:</p>
                <div className="flex items-center justify-center">
                  <input 
                    type="text" 
                    value="0xDA34cA1649cAd55bE915027Fb3be31e26f0F955f" 
                    readOnly 
                    className="bg-gray-100 p-2 rounded-l mr-0 flex-grow max-w-xs text-sm text-gray-700"
                  />
                  <button 
                    onClick={() => copyToClipboard('0xDA34cA1649cAd55bE915027Fb3be31e26f0F955f')}
                    className="bg-bullet-blue text-white px-3 py-2 rounded-r hover:bg-blue-600 transition-colors text-sm"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="merch" className="section-container w-full max-w-6xl">
          <div className="frame-content">
            <h2 className="text-5xl font-bold mb-12 font-lacquer text-bullet-blue">Merch</h2>
            <div className="flex flex-wrap justify-center items-center">
              <div className="w-full md:w-1/2 p-4">
                <img 
                  src={`${process.env.PUBLIC_URL}/img/merch-preview.png`} 
                  alt="Bullet Coin Merchandise" 
                  className="w-full rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full md:w-1/2 p-4">
                <p className="text-xl mb-6 text-white">
                  Show your support for Bullet Coin with our exclusive merchandise! From t-shirts to hoodies, we've got everything you need to rep the herd in style.
                </p>
                <button 
                  onClick={() => window.open("https://merch.basedbullet.com", "_blank")}
                  className="bg-bullet-blue text-white px-6 py-3 rounded font-bold text-lg hover:bg-[#157a9c] transition-colors duration-300"
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="tokenomics" className="section-container w-full max-w-6xl">
          <div className="flex flex-wrap items-center justify-center w-full">
            <div className="w-full md:w-1/2 p-4 flex justify-center items-center">
              <img 
                src={`${process.env.PUBLIC_URL}/img/bulletronaldo.png`} 
                alt="Bullet Ronaldo" 
                className="w-full max-w-sm mx-auto"
              />
            </div>
            <div className="w-full md:w-1/2 p-4">
              <div className="frame-content w-full">
                <h2 className="text-5xl font-bold mb-12 font-lacquer text-bullet-blue">Tokenomics</h2>
                <ul className="list-none p-0 space-y-6 text-xl text-white">
                  <li>Supply 10,000,000,000 BULLET</li>
                  <li>73% Liquidity Pool (Locked)</li>
                  <li>22% Treasury (Locked)</li>
                  <li>5% Team (Locked)</li>
                </ul>
                <p className="text-xl mt-6 font-bold text-white">Renounced Contract</p>
              </div>
            </div>
          </div>
        </section>

        <section id="donate" className="section-container w-full max-w-6xl">
          <div className="frame-content">
            <div className="flex flex-wrap items-center justify-center">
              <div className="w-full md:w-1/2 p-4">
                <div className="inner-frame">
                  <h2 className="text-5xl font-bold mb-12 font-lacquer text-bullet-blue">Donate</h2>
                  <h3 className="text-3xl font-bold mb-6 text-white">Giving Back with Bullet Coin 🌍💧</h3>
                  <p className="text-xl mb-6 text-white">
                    At Bullet Coin, we're not just about memes and markets—we're about making a real impact. That's why we're using the power of our community to bring clean water to those who need it most.
                  </p>
                  <p className="text-xl mb-6 text-white">
                    <strong>Our Mission:</strong> We're raising funds to build water wells in African villages, bringing life-sustaining water to communities that have long struggled without it. Each well we fund means fewer people walking miles for clean water, and more families with the resources they need to thrive.
                  </p>
                  <p className="text-xl mb-6 text-white">
                    Together, we can turn memes into meaningful action. Let's rally the herd and make a difference, one drop at a time.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-4">
                <div className="inner-frame">
                  <h4 className="text-2xl font-bold mb-8 text-white">💧 Donate to the Cause:</h4>
                  <div className="mb-8">
                    <p className="font-bold text-xl mb-4 text-white">Base Name:</p>
                    <div className="flex items-center justify-center">
                      <input 
                        type="text" 
                        value="give.base.eth" 
                        readOnly 
                        className="bg-gray-100 p-2 rounded-l mr-0 flex-grow max-w-xs text-sm text-gray-700"
                      />
                      <button 
                        onClick={() => copyToClipboard('give.base.eth')}
                        className="bg-bullet-blue text-white px-3 py-2 rounded-r hover:bg-blue-600 transition-colors text-sm"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                  <div>
                    <p className="font-bold text-xl mb-4 text-white">Wallet Address:</p>
                    <div className="flex items-center justify-center">
                      <input 
                        type="text" 
                        value="0x1ec6bd36844D2c4A4ABD0fc6E0940493DE1AB85B" 
                        readOnly 
                        className="bg-gray-100 p-2 rounded-l mr-0 flex-grow max-w-xs text-sm text-gray-700"
                      />
                      <button 
                        onClick={() => copyToClipboard('0x1ec6bd36844D2c4A4ABD0fc6E0940493DE1AB85B')}
                        className="bg-bullet-blue text-white px-3 py-2 rounded-r hover:bg-blue-600 transition-colors text-sm"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      
      <footer className="text-white">
        <div className="dock-container">
          <Dock magnification={1.2} distance={50}>
            <DockIcon className="dock-icon">
              <a href="https://t.me/BulletCoinBase" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-telegram"></i>
              </a>
            </DockIcon>
            <DockIcon className="dock-icon">
              <a href="https://x.com/BulletCoinBase" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-x-twitter"></i>
              </a>
            </DockIcon>
            <DockIcon className="dock-icon">
              <a href="https://flooz.xyz/BulletCoinBase" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-chart-line"></i>
              </a>
            </DockIcon>
            <DockIcon className="dock-icon">
              <a href="https://www.dextools.io/app/en/base/pair-explorer/0xc4a9626ce6a2e692831151185227fbf436f3a025" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-tools"></i>
              </a>
            </DockIcon>
            <DockIcon className="dock-icon">
              <a href="https://dexscreener.com/base/0xc4a9626ce6a2e692831151185227fbf436f3a025" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-search-dollar"></i>
              </a>
            </DockIcon>
            <DockIcon className="dock-icon">
              <a href="https://basescan.org/token/0xDA34cA1649cAd55bE915027Fb3be31e26f0F955f" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-search"></i>
              </a>
            </DockIcon>
            <DockIcon className="dock-icon">
              <a href="https://bio.link/basedbullet" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-link"></i>
              </a>
            </DockIcon>
            <DockIcon className="dock-icon">
              <a href="https://merch.basedbullet.com" target="_blank" rel="noopener noreferrer" className="w-full h-full flex items-center justify-center">
                <i className="fas fa-shopping-cart"></i>
              </a>
            </DockIcon>
          </Dock>
        </div>
        <p>&copy; {new Date().getFullYear()} Bullet Coin. All rights reserved.</p>
      </footer>

      {/* Floating Buy Now Button */}
      <button 
        onClick={() => setIsModalOpen(true)}
        className="fixed bottom-4 right-4 bg-bullet-blue text-white px-6 py-3 rounded font-bold text-sm hover:bg-[#157a9c] transition-colors duration-300 shadow-lg z-50 floating-buy-button font-gluten"
      >
        Buy Now
      </button>

      <IframeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
}

export default App;