import React, { useState } from 'react';

const PhotoCarousel = ({ photos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const prevPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto">
      <div className="aspect-w-16 aspect-h-9">
        <img 
          src={photos[currentIndex]} 
          alt={`Photo ${currentIndex + 1}`} 
          className="w-full h-full object-contain max-h-[400px]"
        />
      </div>
      <button 
        onClick={prevPhoto} 
        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
      >
        &#10094;
      </button>
      <button 
        onClick={nextPhoto} 
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
      >
        &#10095;
      </button>
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2">
        {photos.map((_, index) => (
          <span 
            key={index} 
            className={`inline-block w-3 h-3 rounded-full mx-1 ${index === currentIndex ? 'bg-white' : 'bg-gray-400'}`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default PhotoCarousel;