import React, { useState, useEffect } from 'react';

const BlurIn = ({ word, className }) => {
  const [blurAmount, setBlurAmount] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => {
      setBlurAmount(0);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <span
      className={`transition-all duration-1000 ${className}`}
      style={{ filter: `blur(${blurAmount}px)` }}
    >
      {word}
    </span>
  );
};

export default BlurIn;