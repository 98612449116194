import React, { useRef, useEffect } from 'react';

const IframeModal = ({ isOpen, onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1100] p-4">
      <div ref={modalRef} className="bg-white p-4 rounded-lg w-full max-w-[400px] h-[90vh] max-h-[700px] relative">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-xl font-bold text-gray-700 hover:text-gray-900 z-[1200]"
        >
          &times;
        </button>
        <iframe
          title="Flooz Trade"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
          src="https://flooz.xyz/embed/trade?swapDisabled=false&swapNetwork=base&swapToTokenAddress=0xda34ca1649cad55be915027fb3be31e26f0f955f&swapLockToToken=false&onRampDisabled=false&onRampNetwork=base&onRampAsDefault=false&onRampTokenAddress=0xda34ca1649cad55be915027fb3be31e26f0f955f&onRampLockToken=true&network=eth&lightMode=false&backgroundColor=transparent&miniApp=false&miniappIntent=swap"
        ></iframe>
      </div>
    </div>
  );
};

export default IframeModal;